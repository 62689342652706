import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import ONEMOON_LOGO_HORIZONTAL300x from "../img/ONEMOON_LOGO_HORIZONTAL300x.png";
import { Button, makeStyles, Grid, Chip} from "@material-ui/core";
import { useEthers } from "@usedapp/core";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
    container1: {
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "flex",
        gap: theme.spacing(0),
    },
    container2: {
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing(1),
    },
}))

export default function ButtonAppBar({setMenuSelected}: any) {
    const classes = useStyles()
    const { account, activateBrowserWallet, deactivate } = useEthers()
    const isConnected = account !== undefined

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const options = ['Dashboard', 'Token Farm', 'NFTs']
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onMenuItemClick = (index: any) => {
        setAnchorEl(null);
        setMenuSelected(index)
        //console.log(index)
        //console.log(typeof index)
        //console.log(menuSelected)
    }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent">
        <Toolbar >
            <Button
                id="basic-button"
                color="primary"
                variant="contained"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                
            >
                <MenuIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} onClick={() => onMenuItemClick(index)}>{option}</MenuItem>
                ))}
            </Menu>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 , ml: 1} }>
                <img src={ONEMOON_LOGO_HORIZONTAL300x} width="150px"  />
            </Typography>
            {isConnected ? (
                <>
                <Chip label={`${account?.slice(0, 4)} ... ${account?.slice(-4)}`} color="primary" variant="outlined" />
                <Button color="default" variant="contained"
                    onClick={deactivate}>
                    Disconnect
                </Button>
                </>
            ) : (
                <Button color="primary" variant="contained"  
                    onClick={() => activateBrowserWallet()}>
                    Connect
                </Button>
            )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
