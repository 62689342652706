import React, { useState, useEffect } from "react"
import { NFTs, NFTMetadata } from "../components/Main"
import { useEthers, useTokenBalance, useNotifications, useSendTransaction, useContractFunction, useCall } from "@usedapp/core"
import { Button, Input, CircularProgress, Snackbar, Container } from "@material-ui/core"
//import { formatUnits } from "ethers/lib/utils"
import { formatUnits } from "@ethersproject/units"
import { BalanceMsg } from "../components/yourWallet/BalanceMsg"
import helperConfig from "../helper-config.json"
import brownieConfig from "../brownie-config.json"
import { constants, utils, ethers, providers } from "ethers"
import { Contract } from '@ethersproject/contracts'
import networkMapping from "../chain-info/deployments/map.json"
import Hmy_NftSavageBeast from "../chain-info/contracts/Hmy_NftSavageBeast.json"
import { visitLexicalEnvironment } from "typescript"
import Web3 from "web3"
import { config } from "../App"
import { url } from "inspector"
import NftCard from "../components/yourNFTs/NftCard"
import NFTInventory from "../components/yourNFTs/Inventory"
import { makeStyles, Box, Grid, Chip} from "@material-ui/core";
import { flexbox } from '@mui/system';



interface YourNFTsProps {
    supportedNFTs: NFTs
}

interface NFTMetadataProps {
    yourNFTsArray: Array<NFTs>
}
export interface YourNFTMetadataProps {
    yourNftMetadataArray: Array<NFTMetadata>
}

const useStyles = makeStyles((theme) => ({
    container1: {
        container: true,
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(2),
        flexWrap: 'wrap',
        // flexDirection: "row",
        
    },
    container2: {
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing(1),
    },
}))


export const YourNFTListData = ({ supportedNFTs }: YourNFTsProps, { yourNftMetadataArray }: YourNFTMetadataProps) => {
    const classes = useStyles()
    const { address, name, symbol } = supportedNFTs
    const { account, chainId } = useEthers()
    const networkName = chainId ? helperConfig[chainId] : "harmony-ganache"
    const tokenBalance = useTokenBalance(address, account)
    const formattedTokenBalance: number = tokenBalance ? parseFloat(formatUnits(tokenBalance, 0)) : 0
    //const provider = config.readOnlyUrls![chainId!]
    // const provider = ethers.getDefaultProvider('https://api.s0.b.hmny.io')
    //const provider = ethers.getDefaultProvider('kovan')

    //const provider = new ethers.providers.Web3Provider(window.)
    let stringChainID = String(chainId)
    const provider = ethers.getDefaultProvider(brownieConfig["networks"][networkName]["provider"])
    //console.log(provider)
    //console.log(tokenBalance?.toString())
    //console.log(name)
    // console.log(address)
    // console.log(account)
    // console.log(formattedTokenBalance)
    // console.log(chainId)
    // console.log(networkName)


    const proxySavageBeastAddress = chainId ? networkMapping[stringChainID]["Hmy_NftSavageBeast_TransparentUpgradeableProxy"][0] : constants.AddressZero
    const proxySavageBeastABI = Hmy_NftSavageBeast["abi"]
    const proxySavageBeastInterface = new utils.Interface(proxySavageBeastABI)
    const proxySavageBeastContract = new Contract(proxySavageBeastAddress, proxySavageBeastInterface)
    const readOnlyProxySavageBeastContract = new Contract(proxySavageBeastAddress, proxySavageBeastABI, provider)
    const { state, send } = useContractFunction(proxySavageBeastContract, 'sendTokens', { transactionName: 'Transfer' })
    const { status } = state
    const userAccount = chainId ? account! : constants.AddressZero!
    const userAccountFunded = formattedTokenBalance >= 2000
    var isMining = state.status === "Mining" 

    //var MyContract = new Web3.eth.Contract(proxySavageBeastABI, proxySavageBeastAddress);


    // const TransferTokens = (_senderAddress: string, _tokenAddress: string, _amount: string) => {
    //     send(_senderAddress, _tokenAddress, utils.parseEther(_amount))
    // }

    // const handleTokenRequestSubmit = (_senderAddress: string, _tokenAddress: string, _amount: string) => {
    //     return TransferTokens(_senderAddress, _tokenAddress, _amount)
    // }
    const [NFTs, setNFTs] = useState<Array<NFTMetadata>>([])
    const [isLoadingNfts, setIsLoadingNfts] = useState(true)
    const yourNFTListData: NFTMetadata[] = []
    const GetNFTInventory = async (account: string) => {
        // let i = 0
        // let tokenId = 0
        yourNftMetadataArray = []

        var yourNftTokenIndexArray: [] = await readOnlyProxySavageBeastContract.getTokensOfOwner(account).then((response: []) => {
            return(response)
        })
        console.log(yourNftTokenIndexArray)
        console.log(`You have ${yourNftTokenIndexArray.length} NFTs!`)
        console.log("Checking...")
        for (var i = 0; i < yourNftTokenIndexArray.length; i++) {
            const tokenUri = await GetTokenUri(yourNftTokenIndexArray[i]).then((tokenUri: any) => {return(tokenUri)})
            console.log(tokenUri)
            const response = await fetch(tokenUri).then((response: Response) => {return(response)})
            console.log(response)
            const uriData = await response.json().then((uriData: any) => {return(uriData)})
            console.log(uriData)
            await yourNFTListData.push(uriData)
            console.log(yourNFTListData)
        } 
        // while (i < formattedTokenBalance) {
        //     const owner = await CheckOwnerOf(tokenId).then((owner: any) => {return(owner)})
        //     console.log(owner)
        //     if (owner.toString() === account) {
        //         const tokenUri = await GetTokenUri(tokenId).then((tokenUri: any) => {return(tokenUri)})
        //         console.log(tokenUri)
        //         const response = await fetch(tokenUri).then((response: Response) => {return(response)})
        //         const uriData = await response.json().then((uriData: any) => {return(uriData)})
        //         //return(tokenUri)
        //         yourNftMetadataArray.push(uriData)
        //         console.log(yourNftMetadataArray)
        //         i++
                
        //     }
        //     tokenId++
        // }
        console.log(`You have ${i} NFTs!`)
        //console.log(yourNftMetadataArray)
        setNFTs(yourNFTListData)
        setIsLoadingNfts(false)
        
        //return(/* setNFTs(yourNFTListData) */)
    }

    const CheckOwnerOf = async (i: number) => {
        const owner = await readOnlyProxySavageBeastContract.ownerOf(i)
        return (owner)
    }

    const GetTokenUri = async (i: number) => {
        const uri = await readOnlyProxySavageBeastContract.tokenURI(i)
        return (uri)
    }


    useEffect(() => {
        if(isLoadingNfts === true) {
            GetNFTInventory(account!)
            console.log(`Done!`)
            //setNFTs(yourNFTListData)
            //setIsLoadingNfts(false)
        }
    }, [])

    return (
        <>  
            {/* <NFTInventory yourNftMetadataArray={yourNftMetadataArray}/> */}
            {isLoadingNfts && 
            <div>
                <h4>Getting your NFTs...<br /></h4>
                <p>
                    If your NFTs fail to load after 30 seconds, <br />
                    please refresh your browser and try again.
                </p>
            </div>}
            {!isLoadingNfts && <div className={classes.container1}><h2>You have {NFTs.length} Savage Beasts!</h2></div>}
            {!isLoadingNfts && 
                <Box className={classes.container1} >
                    {NFTs.map((nft, index) => {
                        return (
                                <NftCard nft={nft} key={index} />
                        )
                    })}
                </Box>
            }
            {/* <Button
                onClick={() => GetNFTInventory(account!)}
                color="primary"
                variant="contained"
                size="large"
                disabled={isMining || state.status === "PendingSignature" || userAccountFunded}>
                {isMining || state.status === "PendingSignature" ? <CircularProgress size={26} /> : "Show me my NFTs!!"}
            </Button> */}
        </>
        
    )
}
