import react from 'react'
//import fs from 'fs'
//import * as fs from 'fs';
//import { createReadStream } from 'fs';
//import * as fs from 'fs/promises';
import axios from 'axios'
import sampleMetadataSavageBeast from "./metadata/SampleMetadataSavageBeast.json"
import helperConfig from "../helper-config.json"
import { Contract } from '@ethersproject/contracts'
import networkMapping from "../chain-info/deployments/map.json"
import { useEthers, useTokenBalance, useNotifications, useSendTransaction, useContractFunction, useCall } from "@usedapp/core"
import viciousnessMapping from "./viciousnessMapping.json"
import { ethers, providers } from "ethers"
import brownieConfig from "../brownie-config.json"
import Img_Uri from '../img_uri/Img_Uri.json'

export const CreateMetadata = async (tokenId:number, readOnlyProxySavageBeastContract: Contract, chainId: number, randomMod: number) => {
    console.log("Creating metadata...")
    // fs.readFile('../../data/metadata/SampleMetadataSavageBeast.json', 'utf8', (err:any, jsonString:string) => {
    //     if (err) {
    //         return;
    //     }
    //     try {
    //         const customer = JSON.parse(jsonString);
    //     } catch(err) {
    //         console.log('Error parsing JSON string:', err);
    //     }
    // })
    const networkName = chainId ? helperConfig[chainId] : "harmony-ganache"
    console.log(networkName)
    const pinataApiKey = process.env.REACT_APP_PINATA_API_KEY
    console.log(pinataApiKey)
    const pinataSecretApiKey = process.env.REACT_APP_PINATA_API_SECRET
    console.log(pinataSecretApiKey)
    // const speciesKey = await readOnlyProxySavageBeastContract.tokenIdToSpecies(tokenId).then((_speciesKey: number) => {
    //     return(_speciesKey) 
    // }) 
    // console.log(speciesKey)
    const species = await getSpecies(randomMod)
    console.log(species)
    const viciousnessValue = viciousnessMapping[species!]
    console.log(viciousnessValue)
    const imgFilePath = `../../img/nft_savage_beast_img/${species?.toLowerCase()}.png`
    console.log(imgFilePath)
    const provider = ethers.getDefaultProvider(brownieConfig["networks"][networkName]["provider"])
    console.log(provider)
    const latestBlock = await provider.getBlock('latest').then((block) => {
        return(block)
    })
    console.log(latestBlock)
    const blockTimestamp = latestBlock['timestamp']
    console.log(blockTimestamp)
    // const imgBlobFile = await createImgBlobFile(imgFilePath, species!).then((imgBlobFile) => {
    //     return(imgBlobFile)
    // })
    console.log(species!.toLowerCase())
    const imageUri = Img_Uri[randomMod][species!.toLowerCase()]
    
    console.log(imageUri)

    const metadataJSON = {
        "species": `${species}`,
        "description": `A terribly vicious ${species}! Ferocious and deadly!`,
        "image": `${imageUri}`,
        "attributes": [
            {
                "trait_type": "Viciousness",
                "value": `${viciousnessValue}`
            },
            {
                "trait_type": "Mint Timestamp",
                "value": `${blockTimestamp}` 
            },
            {
                "trait_type": "Most Recent Claim",
                "value": `${blockTimestamp}`
            },
            {
                "trait_type": "Market Status",
                "value": "Not for sale"
            },
            {
                "trait_type": "Price",
                "value": 0
            }
        ] 
    }
    // console.log(JSON.stringify(metadataJSON))
    // fetch(`../../data/metadata/${networkName}/savageBeastMetadata/TokenUri.json`, {
    //     method: 'POST',
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(metadataJSON)
    // }).then((response) => {
    //     console.log(response)
    // })

    console.log(metadataJSON)
    
    const tokenUri = pinJSONToIPFS(pinataApiKey!, pinataSecretApiKey!, metadataJSON, tokenId, species!).then((tokenUri: string) => {
        console.log(tokenUri)
        return(tokenUri)
    })
    
    return(tokenUri)
};

// async function createImgBlobFile(imgFilePath: string, species: string){
//     let response = await fetch(imgFilePath);
//     let data = await response.blob();
//     let metadata = {
//       type: 'image/png'
//     };
//     let file = new File([data], `${species.toLowerCase()}.png`, metadata);
//     // ... do something with the file or return it
//     return(file)
// }


// const pinFileToIPFS = (imgBlobFile: File, pinataApiKey: string, pinataSecretApiKey: string, species: string) => {
//     const FormData = require('form-data');
//     const axios = require('axios');

//     const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
//     //we gather a local file for this example, but any valid readStream source will work here.
//     const fileReader = new FileReader()
//     let data = new FormData();
//     data.append({'file': fileReader.readAsDataURL(imgBlobFile)}/* fs.createReadStream(imgFilePath) */); // Placeholder from example: './yourfile.png'

//     //If you want to include metadata with your file (optional):
//     //You'll need to make sure that the metadata is in the form of a JSON object that's been convered to a string
//     //metadata is optional
//     const metadata = JSON.stringify({
//         name: `${species.toLowerCase()}.png`,
//         // keyvalues: {
//         //     exampleKey: 'exampleValue'
//         // }
//     });
//     data.append('pinataMetadata', metadata);

    //     //pinataOptions are optional
    //     const pinataOptions = JSON.stringify({
    //         cidVersion: 0,
    //         customPinPolicy: {
    //             regions: [
    //                 {
    //                     id: 'FRA1',
    //                     desiredReplicationCount: 1
    //                 },
    //                 {
    //                     id: 'NYC1',
    //                     desiredReplicationCount: 2
    //                 }
    //             ]
    //         }
    //     });
    //     data.append('pinataOptions', pinataOptions);

//     return axios
//         .post(url, data, {
//             maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
//             headers: {
//                 'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
//                 pinata_api_key: pinataApiKey,
//                 pinata_secret_api_key: pinataSecretApiKey
//             }
//         })
//         .then(function (response:any) {
//             //handle response here
//             const responseJson = response.json()
//             const ipfsHash = responseJson["IpfsHash"]
//             const imageUri = `https://ipfs.io/ipfs/${ipfsHash}?filename=${species}.png`
//             console.log(imageUri)
//             return(imageUri)
//         })
//         .catch(function (error: any) {
//             console.log(error)
//             return(error)
//         });
// };

const pinJSONToIPFS = (pinataApiKey: string, pinataSecretApiKey: string, metadataJSON: object, tokenId: number, species: string) => {
    const axios = require('axios');
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    return axios
        .post(url, metadataJSON, {
            headers: {
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataSecretApiKey
            },
            pinataMetadata: {
                name: `${tokenId}-${species}.json`
            }
        })
        .then(function (response: any) {
            console.log(response)
            const ipfsHash = response["data"]["IpfsHash"]
            // const tokenUri = `https://ipfs.io/ipfs/${ipfsHash}?filename=${tokenId}-${species}.json`
            const tokenUri = `https://ipfs.io/ipfs/${ipfsHash}`
            console.log("image URI set!")
            console.log(tokenUri)
            return(tokenUri)
        })
        .catch(function (error: any) {
            console.log(error)
            return(error)
        });
};

function getSpecies(speciesKey: number) {
    switch(speciesKey) {
        case 0: return "BAT";
        case 1: return "BEAR";
        case 2: return "BUNNY";
        case 3: return "CAT";
        case 4: return "COW";
        case 5: return "DOG";
        case 6: return "ELEPHANT";
        case 7: return "ELK";
        case 8: return "GNU";
        case 9: return "GOAT";
        case 10: return "HIPPO";
        case 11: return "HORSE";
        case 12: return "KANGAROO";
        case 13: return "KOALA";
        case 14: return "LADYBUG";
        case 15: return "LEOPARD";
        case 16: return "LION";
        case 17: return "MONKEY";
        case 18: return "MOUSE";
        case 19: return "OSTRICH";
        case 20: return "OWL";
        case 21: return "PENGUIN";
        case 22: return "PIG";
        case 23: return "PTERODACTYL";
        case 24: return "RHINO";
        case 25: return "SHEEP";
        case 26: return "SQUIRREL";
        case 27: return "TIGER";
        case 28: return "TRICERATOPS";
        case 29: return "UNICORN";
        case 30: return "ZEBRA";
    }
}



