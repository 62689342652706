import React, { useState, useEffect } from "react"
import { Token } from "../Main"
import { useEthers, useTokenBalance, useNotifications, transactionErrored } from "@usedapp/core"
import { formatUnits } from "@ethersproject/units"
import { Button, Input, CircularProgress, Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { useStakeTokens } from "../../hooks/useStakeTokens"
import { utils } from "ethers"

export interface StakeFormProps {
    token: Token
}

export const StakeForm = ({ token }: StakeFormProps) => {
    const { address: tokenAddress, name } = token
    const { account } = useEthers()
    const tokenBalance = useTokenBalance(tokenAddress, account)
    const formatedTokenBalance: number = tokenBalance ? parseFloat(formatUnits(tokenBalance, 18)) : 0
    const { notifications } = useNotifications()

    const [amount, setAmount] = useState<number | string | Array<number | string>>(0)
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAmount = event.target.value === "" ? "" : Number(event.target.value)
        setAmount(newAmount)
        console.log(newAmount)
    }

    const { approveAndStake, state: approveAndStakeErc20State } = useStakeTokens(tokenAddress)
    const handleStakeSubmit = () => {
        const amountAsWei = utils.parseEther(amount.toString())
        return approveAndStake(amountAsWei.toString())
    }

    const isMining = approveAndStakeErc20State.status === "Mining"
    const [showErc20ApprovalPending, setShowErc20ApprovalPending] = useState(false)
    const [showStakeTokenPending, setShowStakeTokenPending] = useState(false)
    const [showErc20ApprovalSuccess, setShowErc20ApprovalSuccess] = useState(false)
    const [showStakeTokenSuccess, setShowStakeTokenSuccess] = useState(false)
    const handleCloseSnackbar = () => {
        setShowErc20ApprovalSuccess(false)
        setShowStakeTokenSuccess(false)
    }

    useEffect(() => {
        if (notifications.filter(
            (notification) => 
            notification.type === "transactionStarted" &&
            notification.transactionName === "Approve ERC20 transfer").length > 0) {
                console.log("Approval Pending...")
                setShowErc20ApprovalPending(true)
                setShowErc20ApprovalSuccess(false)
                setShowStakeTokenPending(false)
                setShowStakeTokenSuccess(false)
        }
        if (notifications.filter(
            (notification) => 
            notification.type === "transactionSucceed" &&
            notification.transactionName === "Approve ERC20 transfer").length > 0) {
                console.log("Approved!")
                setShowErc20ApprovalPending(false)
                setShowErc20ApprovalSuccess(true)
                setShowStakeTokenPending(false)
                setShowStakeTokenSuccess(false)
                
                
        }
        if (notifications.filter(
            (notification) =>
            notification.type === "transactionStarted" &&
            notification.transactionName === "Stake Tokens").length > 0) {
                console.log("Stake Transaction Pending...")
                setShowErc20ApprovalPending(false)
                setShowErc20ApprovalSuccess(false)
                setShowStakeTokenPending(true)
                setShowStakeTokenSuccess(false)
        } 
        if (notifications.filter(
            (notification) =>
            notification.type === "transactionSucceed" &&
            notification.transactionName === "Stake Tokens").length > 0) {
                console.log("Tokens Staked!")
                setShowErc20ApprovalPending(false)
                setShowErc20ApprovalSuccess(false)
                setShowStakeTokenPending(false)
                setShowStakeTokenSuccess(true)
        }
    }, [notifications, showErc20ApprovalSuccess, showStakeTokenSuccess])

    return (
        <>
        <div>
            <Input onChange={handleInputChange} />
            <Button
                onClick={handleStakeSubmit}
                color="primary"
                variant="outlined"
                size="large"
                disabled={isMining}>
                {isMining ? <CircularProgress size={26} /> : "Stake!!"}
            </Button>
        </div>
        <Snackbar
            open={showErc20ApprovalPending}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    ERC-20 token transfer approval pending...
                </Alert>
        </Snackbar>
        <Snackbar
            open={showErc20ApprovalSuccess}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    ERC-20 token transfer approved! Now approve the second transaction.
                </Alert>
        </Snackbar>
        <Snackbar
            open={showStakeTokenPending}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Token stake pending...
                </Alert>
        </Snackbar>
        <Snackbar
            open={showStakeTokenSuccess}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Tokens staked!
                </Alert>
        </Snackbar>
        </>
    )
}