//import './App.css';
import { useState } from "react"
import { DAppProvider, Config, ChainId, Kovan, Rinkeby, Harmony, DEFAULT_SUPPORTED_CHAINS } from "@usedapp/core" 
import { Header } from "./components/Header"
import { Container } from "@material-ui/core"
import { Main } from "./components/Main"
import { getDefaultProvider } from 'ethers'
import { HarmonyTestnet, HarmonyGanache } from './HarmonyTestnet'

export const config: Config = {
  //readOnlyChainId: HarmonyGanache.chainId,
  readOnlyUrls: {
    [Kovan.chainId]: getDefaultProvider('kovan'),
    [Rinkeby.chainId]: getDefaultProvider('rinkeby'),
    [HarmonyGanache.chainId]: 'http://127.0.0.1:9500',
    [HarmonyTestnet.chainId]: 'https://api.s0.b.hmny.io',
    [Harmony.chainId]: 'https://api.s0.t.hmny.io',
  },
  //supportedChains: [ChainId.Kovan, ChainId.Rinkeby, ChainId.Harmony, /* 1666700000, */ /* 1666800000], */ ],
  // notifications: {
  //   expirationPeriod: 1000,
  //   checkInterval: 1000
  // },
  networks: [...DEFAULT_SUPPORTED_CHAINS, HarmonyTestnet, Harmony, HarmonyGanache, Kovan, Rinkeby]
}
    
function App() {
  return (
    <DAppProvider config={config}>
      <Container maxWidth="md">
        <Main />
      </Container>
    </DAppProvider>
  );
}

export default App;
