import * as React from 'react';
import { NFTMetadata } from "../../components/Main"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export interface YourNFTMetadataProps {
  NFTs: Array<NFTMetadata>
}

interface YourMetadataProps {
  nft: NFTMetadata
}

export default function NFTCard({ nft }: YourMetadataProps) {
  return (
    <Card raised={true} sx={[{ Width: 200 },  { maxHeight: 400 }, { boxShadow: 20  }, {border: 3}, {borderColor: '#696969'},  { bgcolor: '#696969'}, {p: 0} ,
      { borderRadius: 2 } ]} >
      <CardActionArea >
        <CardMedia
          component="img"
          src={`${nft.image}?w=248&fit=crop&auto=format`}
          srcSet={`${nft.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
          object-fit="unset"
          height="200"
          //image={nft.image}
          alt={nft.species}
          sx={{bgcolor:"#000000"}}
        />
        <CardContent sx={[{ Height: '100%' }, {bgcolor:'#696969'}, {color: '#000000'}]}>
          <Typography gutterBottom variant="h5" component="div">
            {nft.species}
          </Typography>
          {/* <Typography variant="body2" color="text.primary">
            {nft.description}
          </Typography> */}
          <Typography variant="subtitle1" color="text.primary">
            {nft.attributes[0].trait_type}: {nft.attributes[0].value}
          </Typography>
          {/* <Typography variant="subtitle1" color="text.primary">
            {nft.attributes[1].trait_type}: {nft.attributes[1].value}
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {nft.attributes[2].trait_type}: {nft.attributes[2].value}
          </Typography> */}
          {/* <Typography variant="subtitle1" color="text.primary">
            {nft.attributes[3].trait_type}: {nft.attributes[3].value}
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {nft.attributes[4].trait_type}: {nft.attributes[4].value}
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
