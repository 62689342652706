import React, { useState, useEffect } from "react"
import { NFTs } from "../Main"
import { useEthers, useTokenBalance, useNotifications, useSendTransaction, useContractFunction } from "@usedapp/core"
import { Button, Input, CircularProgress, Snackbar } from "@material-ui/core"
//import { formatUnits } from "ethers/lib/utils"
import { formatUnits } from "@ethersproject/units"
import { NftBalanceMsg } from "./NftBalanceMsg"
import helperConfig from "../../helper-config.json"
import { constants, utils, ethers, providers } from "ethers"
import { Contract } from '@ethersproject/contracts'
import networkMapping from "../../chain-info/deployments/map.json"
import Hmy_NftSavageBeast from "../../chain-info/contracts/Hmy_NftSavageBeast.json"
import {CreateMetadata} from "../../data/CreateMetadata"
import brownieConfig from "../../brownie-config.json"
import { createUnparsedSourceFile, tokenToString } from "typescript"
import { useMintNft } from "../../hooks/useMintNft"



export interface YourNFTsProps {
    NFTs: NFTs
}



export const MintNFTs = ({ NFTs }: YourNFTsProps) => {
    const { tabLabel, address, name, symbol } = NFTs
    const { account, chainId } = useEthers()
    const networkName = chainId ? helperConfig[chainId] : "harmony-ganache"
    const NFTtokenBalance = useTokenBalance(address, account)
    const formattedTokenBalance: number = NFTtokenBalance ? parseFloat(formatUnits(NFTtokenBalance, 0)) : 0
    //console.log(tokenBalance?.toString())
    //console.log(name)
    // console.log(address)
    // console.log(account)
    // console.log(formattedTokenBalance)
    // console.log(chainId)
    // console.log(networkName)
    let stringChainID = String(chainId)
    const provider = ethers.getDefaultProvider(brownieConfig["networks"][networkName]["provider"])

    const proxyTokenFarmAddress = chainId ? networkMapping[stringChainID]["TokenFarm_TransparentUpgradeableProxy"][0] : constants.AddressZero
    const proxySavageBeastAddress = chainId ? networkMapping[stringChainID]["Hmy_NftSavageBeast_TransparentUpgradeableProxy"][0] : constants.AddressZero
    const proxySavageBeastABI = Hmy_NftSavageBeast["abi"]
    const proxySavageBeastInterface = new utils.Interface(proxySavageBeastABI)
    const proxySavageBeastContract = new Contract(proxySavageBeastAddress, proxySavageBeastInterface)
    const readOnlyProxySavageBeastContract = new Contract(proxySavageBeastAddress, proxySavageBeastABI, provider)
    const MoonBoiTokenProxyAddress = chainId ? networkMapping[stringChainID]["MoonBoiToken_TransparentUpgradeableProxy"][0] : constants.AddressZero
    const MoonBoiTokenBalance = useTokenBalance(MoonBoiTokenProxyAddress, account)
    const MoonBoiformattedTokenBalance: number = MoonBoiTokenBalance ? parseFloat(formatUnits(MoonBoiTokenBalance, 18)) : 0

   
    const { TransferTokens, payMintFeeState, setTokenUri, setTokenUriState, mintNFT, mintNFTState  } = useMintNft()
    const userAccount = chainId ? account! : constants.AddressZero!
    // TODO: get Moonboi token address and check balance to ensure funded w/ at least 1000 tokens for minting
    const userAccountFunded = MoonBoiformattedTokenBalance >= 200
    var isMining = payMintFeeState.status === "Mining" || mintNFTState.status === "Mining"

    //TODO: collect mint fee
  
    const HandleMintSubmit = async (proxyTokenFarmAddress: string, _amount: string) => {

        return await TransferTokens(proxyTokenFarmAddress, _amount)
       

        //await proxySavageBeastContract.transferTokens(_senderAddress, _tokenAddress, _amount)
        
        //TODO: collect mint fee
        //return TransferTokens(_senderAddress, _tokenAddress, _amount)
        
            
        // .then(() => {
        //     CreateMetadata(stringTokenID, readOnlyProxySavageBeastContract).then((tokenUri) => {
        //         proxySavageBeastContract.setTokenUri(tokenUri) 
        //     })
            // set state
            

        //TODO: Mint NFT, after successfull token transfer

        //TODO: Create Metadata, upload to Pinata, and set URI
    }

    /* const { notifications } = useNotifications()
    const [showErc20ApprovalPending, setShowErc20ApprovalPending] = useState(false)
    const [showStakeTokenPending, setShowStakeTokenPending] = useState(false)
    const [showErc20ApprovalSuccess, setShowErc20ApprovalSuccess] = useState(false)
    const [showStakeTokenSuccess, setShowStakeTokenSuccess] = useState(false)
    const handleCloseSnackbar = () => {
        setShowErc20ApprovalSuccess(false)
        setShowStakeTokenSuccess(false)
    } */

    /* useEffect(() => {
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionStarted" &&
                notification.transactionName === "Approve ERC20 transfer").length > 0) {
            console.log("Approval Pending...")
            setShowErc20ApprovalPending(true)
            setShowErc20ApprovalSuccess(false)
            setShowStakeTokenPending(false)
            setShowStakeTokenSuccess(false)
        }
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionSucceed" &&
                notification.transactionName === "Approve ERC20 transfer").length > 0) {
            console.log("Approved!")
            setShowErc20ApprovalPending(false)
            setShowErc20ApprovalSuccess(true)
            setShowStakeTokenPending(false)
            setShowStakeTokenSuccess(false)


        }
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionStarted" &&
                notification.transactionName === "Stake Tokens").length > 0) {
            console.log("Stake Transaction Pending...")
            setShowErc20ApprovalPending(false)
            setShowErc20ApprovalSuccess(false)
            setShowStakeTokenPending(true)
            setShowStakeTokenSuccess(false)
        }
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionSucceed" &&
                notification.transactionName === "Stake Tokens").length > 0) {
            console.log("Tokens Staked!")
            setShowErc20ApprovalPending(false)
            setShowErc20ApprovalSuccess(false)
            setShowStakeTokenPending(false)
            setShowStakeTokenSuccess(true)
        }
    }, [notifications, showErc20ApprovalSuccess, showStakeTokenSuccess]) */


    return (
        <div>
            <h1>
                Can you tame the savage beast?
            </h1>
            <h2>
                Great rewards are in store if you can.
            </h2>
            <p>
                Each savage beast is a vicious, deadly creature. Terrifying even to look at.<br />
                Collect daily Harmoony token rewards according to the viciousness rating: <br />
                <li>Viciousness: 10 = 10 Harmoony per day (41.9 % probability)  <br /></li>
                <li>Viciousness: 20 = 20 Harmoony per day (29.0 % probability)  <br /></li>
                <li>Viciousness: 30 = 30 Harmoony per day (12.9 % probability)  <br /></li>
                <li>Viciousness: 40 = 40 Harmoony per day (9.7 % probability)  <br /></li>
                <li>Viciousness: 50 = 50 Harmoony per day (6.5 % probability)  <br /></li>
            </p>
            <p> NFT: {name}, {symbol} <br />
                Contract Address: {address} <br /><br />
            </p>
            <p>
                <h4>Mint Price: 200 MoonBoi tokens <br /></h4>
                Requires two confirmations (1 - mint fee, 2 - Token mint)
            </p>

            <div>
                {!userAccountFunded ? (
                    <>
                        <p color="success">You don't have enough MoonBoi tokens to mint NFTs. <br />
                        You can request more tokens from the token farm</p>
                    </>
                ) : (
                    <>
                        <Button
                            onClick={() => HandleMintSubmit(proxyTokenFarmAddress, '200') }
                            color="primary"
                            variant="contained"
                            size="large"
                            disabled={isMining || payMintFeeState.status == "PendingSignature" || mintNFTState.status === "PendingSignature" || !userAccountFunded}>
                            {isMining || payMintFeeState.status == "PendingSignature" || mintNFTState.status === "PendingSignature" ? <CircularProgress size={26} /> : "Mint Savage Beast!!"}
                        </Button>
                        <p>Token Transfer Status: {payMintFeeState.status}</p>
                        <p>Token Mint Status: {mintNFTState.status}</p>
                        
                        {/* <p>Token URI Status: {setTokenUriState.status}</p> */}
                    </>
                )}
            </div>
            <p>
                {mintNFTState.status === 'Exception' && <div>{mintNFTState.errorMessage}</div>}
            </p>
            <p>
                <NftBalanceMsg
                    label={`Your current ${name} balance:`}
                    amount={formattedTokenBalance} />
            </p>
        </div>
    )
}
