import React, { useEffect, useState } from "react"
import { useEthers } from "@usedapp/core"
import {YourDashboard} from "./dashboard/YourDashboard"
import helperConfig from "../helper-config.json"
import networkMapping from "../chain-info/deployments/map.json"
import { constants } from "ethers"
import harmoony_3 from "../img/harmoony_3.png"
import moonboi from "../img/moonboi_2.png"
import { YourWallet } from "./yourWallet"
import { TokenFarmContract } from "./tokenFarmContract"
import { YourNFTs} from "./yourNFTs"
import { Snackbar, Typography, makeStyles } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { isNullOrUndefined } from "util"
import ButtonAppBar from "./AppBar"
import { GetContractAddress } from "../utils/GetContractAddress"
import {nftListData} from "../data/NFTListData"
// import {yourNftListData} from "../data/YourNFTListData"

export type Token = {
    image: string
    address: string
    name: string
    symbol: string
}
export type NFTs = {
  tabLabel : string
  address: string
  name: string
  symbol: string
  nftListData: {
    category: string
    img: string
    title: string
    author: string
  }[]
  // yourNFTistData: NFTMetadata
}

export type NFTMetadata = {
  species: string
  description: string
  image: string
  attributes: {
    Trait_type: string
    value: number
    // mintDate: Date
    // mostRecentClaim: Date
    // marketStatus: string
    // currency: number
    // price: number
  }
}

export type NFTTabLabels = {
  tabLabel: string
  address: string
  name: string
  symbol: string
}



const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.common.white,
        textAlign: "center",
        padding: theme.spacing(1),
        margin: theme.spacing(0),
        border: theme.spacing(0),    
    },
    container: {
        color: theme.palette.primary.light,
        textAlign: "center",
        padding: theme.spacing(0),
        margin: theme.spacing(0),
        border: theme.spacing(0),
        font: "50"
    },
}))



export const Main = () => {
    // Show token values from the wallet
    // Get the address of different tokens
    // Get the balance of the user wallet

    // in deploy.py:
    // Send the brownie-config to our 'src' folder 
    // Send the build folder (because it has all the mocks and other addresses we need)

    const classes = useStyles()
    const { chainId, error } = useEthers()
    const networkName = chainId ? helperConfig[chainId] : "harmony-test"
    let stringChainID = chainId ? String(chainId) : "1666700000"
    //use console.log to view in browser inspector while developing
    console.log(typeof chainId)
    console.log(chainId)
    console.log(typeof stringChainID)
    console.log(stringChainID)
    console.log(networkName)
    const MoonBoiTokenProxyAddress = chainId ? networkMapping[stringChainID]["MoonBoiToken_TransparentUpgradeableProxy"][0] : constants.AddressZero!
    const HarmoonyTokenProxyAddress = chainId ? networkMapping[stringChainID]["HarmoonyToken_TransparentUpgradeableProxy"][0] : constants.AddressZero!
    const SagageBeastProxyAddress = chainId ? networkMapping[stringChainID]["Hmy_NftSavageBeast_TransparentUpgradeableProxy"][0] : constants.AddressZero!
    
    //console.log(MoonBoiTokenProxyAddress)
    //console.log(HarmoonyTokenProxyAddress)

      /**
   * Our single central location to store info on support tokens.
   * This is the only place you'll need to add a new token to get it to display in the UI!
   * 
   * Modularize the addresses like with `dappTokenAddress`
   * To make it chain agnostic
   */
    const supportedTokens: Array<Token> = [
      {
          image: moonboi,
          address: MoonBoiTokenProxyAddress,
          name: "MoonBoi",
          symbol: "MBOI"
      },
      {
          image: harmoony_3,
          address: HarmoonyTokenProxyAddress,
          name: "Harmoony",
          symbol: "HMOONY"
      },
    ]
    const supportedNFTs: Array<NFTs> = [
      {
        //image: moonboi,
        tabLabel: "Mint",
        address: SagageBeastProxyAddress,
        name: "Savage Beast",
        symbol: "BEAST",
        nftListData: nftListData,
        //yourNFTistData: yourNftListData
    },
    {
        //image: moonboi,
        tabLabel: "My NFTs",
        address: SagageBeastProxyAddress,
        name: "Savage Beast",
        symbol: "BEAST",
        nftListData: nftListData,
        // yourNFTistData: yourNftListData
    },
    {
      //image: moonboi,
      tabLabel: "Marketplace",
      address: SagageBeastProxyAddress,
      name: "Savage Beast",
      symbol: "BEAST",
      nftListData: nftListData,
      // yourNFTistData: yourNftListData
    },
    {
      //image: moonboi,
      tabLabel: "My Rewards",
      address: SagageBeastProxyAddress,
      name: "Savage Beast",
      symbol: "BEAST",
      nftListData: nftListData,
      // yourNFTistData: yourNftListData
    },
    ]

    const tabLabels: Array<NFTTabLabels> = [
      {
          //image: moonboi,
          tabLabel: "Mint",
          address: SagageBeastProxyAddress,
          name: "Savage Beast",
          symbol: "BEAST"
      },
      {
          //image: moonboi,
          tabLabel: "My NFTs",
          address: SagageBeastProxyAddress,
          name: "Savage Beast",
          symbol: "BEAST"
      },
    ]

    const [showNetworkError, setShowNetworkError] = useState(false)

    const handleCloseNetworkError = (
      event: React.SyntheticEvent | React.MouseEvent,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return
      }
  
      showNetworkError && setShowNetworkError(false)
    }
  
    /**
     * useEthers will return a populated 'error' field when something has gone wrong.
     * We can inspect the name of this error and conditionally show a notification
     * that the user is connected to the wrong network.
     */
    useEffect(() => {
      if (error && error.name === "UnsupportedChainIdError") {
        !showNetworkError && setShowNetworkError(true)
      } else {
        showNetworkError && setShowNetworkError(false)
      }
    }, [error, showNetworkError])

    const [menuSelected, setMenuSelected] = useState(1);
    console.log(menuSelected)
    console.log(typeof menuSelected)
    console.log(" Hell yeah!")
    
    return (
        
        <>
          <ButtonAppBar setMenuSelected={setMenuSelected} />
          <Typography
            variant="h2"
            component="h1"
            classes={{
              root: classes.title,
            }}
          >
            <div>
              <h6 className={classes.title}>Welcome to the <br/>ONEMOON Playground DAPP!</h6>
              {/* <h6 className={classes.container} color="secondary"> The dumbest dapp you'll ever use</h6> */}
            </div>
          </Typography>
          {menuSelected === 0 ? (
            <div>
              <div>
                <YourDashboard />
              </div>
            </div>
          ) : (<></> )} 
          {menuSelected === 1 ? (
            <div>
              <YourWallet supportedTokens={supportedTokens} />
              <TokenFarmContract supportedTokens={supportedTokens} />
            </div>
          ) : (<></>)}
          {menuSelected === 2 ? (
            <div>
              <YourNFTs supportedNFTs={supportedNFTs}  />
            </div>
          ) : (<></> )}
          
          <Snackbar
            open={showNetworkError}
            autoHideDuration={5000}
            onClose={handleCloseNetworkError}
          >
            <Alert onClose={handleCloseNetworkError} severity="warning">
              You gotta connect to the Harmony Testnet!
            </Alert>
          </Snackbar>
        </>
      )


}
