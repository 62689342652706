import { Chain } from '@usedapp/core'

export const HarmonyTestnet: Chain = {
    chainId: 1666700000,
    chainName: 'HarmonyTestnet',
    isTestChain: true,
    isLocalChain: false,
    multicallAddress: '0xd078799c53396616844e2fa97f0dd2b4c145a685',
    getExplorerAddressLink: (address: string) => `https://explorer.pops.one/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://explorer.pops.one/tx/${transactionHash}`,
};
export const HarmonyGanache: Chain = {
    chainId: 1337,
    chainName: 'HarmonyGanache',
    isTestChain: false,
    isLocalChain: true,
    multicallAddress: '0x0000000000000000000000000000000000000000',
    getExplorerAddressLink: (address: string) => ``,
    getExplorerTransactionLink: (transactionHash: string) => ``,
};