import { NFTs, NFTTabLabels, NFTMetadata } from "../Main"
import React, { useState } from "react"
import { Box, makeStyles } from "@material-ui/core"
import { TabContext, TabList, TabPanel } from "@material-ui/lab"
import { Tab } from "@material-ui/core"
import TitlebarBelowImageList from "./Inventory"
import {YourRewards} from "./YourRewards"
import {Marketplace} from "./Marketplace"
import { MintNFTs } from "./MintNFTs"
import {YourNFTListData} from "../../data/YourNFTListData"
import NftCard from "./NftCard"



interface YourNFTsProps {
    supportedNFTs: Array<NFTs>
}

interface NFTTabLabelProps {
    tabLabels: Array<NFTTabLabels>
}

interface YourNFTMetadataProps {
    yourNftMetadataArray: Array<NFTMetadata>
}



const useStyles = makeStyles((theme) => ({
    tabContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(4),
        justifyContent: 'center'
    },
    box: {
        // backgroundColor: '#696969',
        backgroundColor: '#202121',
        borderRadius: "25px",
        color: '#9da1a1',
        minHeight: 1600
    },
    header: {
        color: "white"
    }
}))

export const YourNFTs = ({ supportedNFTs }: YourNFTsProps, { tabLabels }: NFTTabLabelProps) => {
    const [selectedNFTsIndex, setSelectedNFTsIndex] = useState<number>(0)
    const [selectedNFTTabLabel, setSelectedNFTTabLabel] = useState<number>(0)
    const handleNFTChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setSelectedNFTsIndex(parseInt(newValue))
    }
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setSelectedNFTTabLabel(parseInt(newValue))
    }

    const classes = useStyles()
    return (
        <Box>
            <h1 className={classes.header}> Your NFTs</h1>
            <Box className={classes.box}  >
                <TabContext value={selectedNFTsIndex.toString()}>
                    <TabList onChange={handleNFTChange} aria-label="stake form tabs" >
                        {supportedNFTs.map((NFTs, index) => {
                            return (
                                <Tab label={NFTs.tabLabel}
                                    value={index.toString()}
                                    key={index} />
                            )
                        })}
                    </TabList>
                        {supportedNFTs.map((NFTs, index) => {
                            return (
                                <TabPanel value={index.toString()} key={index}>
                                    {index === 0 ? (
                                        <div className={classes.tabContent}>
                                            <MintNFTs NFTs={supportedNFTs[selectedNFTsIndex]} />
                                        </div>
                                    ) : (<></>)}  
                                    {index === 1 ? (
                                        <div className={classes.tabContent}>
                                            <div>
                                                {/* <TitlebarBelowImageList NFTs={supportedNFTs[selectedNFTsIndex]} /> */}
                                                <YourNFTListData supportedNFTs={supportedNFTs[selectedNFTsIndex]}/>
                                            </div>
                                            {/* <div>
                                                {<TitlebarBelowImageList NFTs={supportedNFTs[selectedNFTsIndex]} />}
                                            </div> */}
                                        
                                        </div>
                                    ) : (<></>)}  
                                    {index === 2 ? (
                                        <div className={classes.tabContent}>
                                        <Marketplace />
                                        </div>
                                    ) : (<></>)} 
                                    {index === 3 ? (
                                        <div className={classes.tabContent}>
                                        <YourRewards />
                                        </div>
                                    ) : (<></>)}                               
                                </TabPanel>
                            )
                        })}
                </TabContext>
            </Box>
        </Box>
    )
}