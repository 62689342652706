import React, { useState, useEffect } from "react"
import { Token } from "../Main"
import { useEthers, useTokenBalance, useNotifications, useSendTransaction, useContractFunction } from "@usedapp/core"
import { Button, Input, CircularProgress, Snackbar } from "@material-ui/core"
//import { formatUnits } from "ethers/lib/utils"
import { formatUnits } from "@ethersproject/units"
import { BalanceMsg } from "./BalanceMsg"
import helperConfig from "../../helper-config.json"
import { constants, utils } from "ethers"
import { Contract } from '@ethersproject/contracts'
import networkMapping from "../../chain-info/deployments/map.json"
import tokenFarmcontract from "../../chain-info/contracts/TokenFarm.json"




export interface WalletBalanceProps {
    token: Token
}

export const WalletBalance = ({ token }: WalletBalanceProps) => {
    const { image, address, name, symbol } = token
    const { account, chainId } = useEthers()
    const networkName = chainId ? helperConfig[chainId] : "harmony-ganache"
    const tokenBalance = useTokenBalance(address, account)
    const formattedTokenBalance: number = tokenBalance ? parseFloat(formatUnits(tokenBalance, 18)) : 0
    //console.log(tokenBalance?.toString())
    //console.log(name)
    // console.log(address)
    // console.log(account)
    // console.log(formattedTokenBalance)
    // console.log(chainId)
    // console.log(networkName)
    let stringChainID = String(chainId)

    const proxyTokenFarmAddress = chainId ? networkMapping[stringChainID]["TokenFarm_TransparentUpgradeableProxy"][0] : constants.AddressZero
    const proxyTokenFarmABI = tokenFarmcontract["abi"]
    const proxyTokenFarmInterface = new utils.Interface(proxyTokenFarmABI)
    const proxyTokenFarmContract = new Contract(proxyTokenFarmAddress, proxyTokenFarmInterface)
    const { state, send } = useContractFunction(proxyTokenFarmContract, 'sendTokens', { transactionName: 'Transfer' })
    const { status } = state
    const userAccount = chainId ? account! : constants.AddressZero!
    const userAccountFunded = formattedTokenBalance >= 2000
    var isMining = state.status === "Mining"
    
    
    const TransferTokens = (_senderAddress: string, _tokenAddress: string, _amount: string) => {
        send(_senderAddress, _tokenAddress, utils.parseEther(_amount))
    }

    const handleTokenRequestSubmit = (_senderAddress: string, _tokenAddress: string, _amount: string) => {
        return TransferTokens(_senderAddress, _tokenAddress, _amount)
    }

    /* const { notifications } = useNotifications()
    const [showErc20ApprovalPending, setShowErc20ApprovalPending] = useState(false)
    const [showStakeTokenPending, setShowStakeTokenPending] = useState(false)
    const [showErc20ApprovalSuccess, setShowErc20ApprovalSuccess] = useState(false)
    const [showStakeTokenSuccess, setShowStakeTokenSuccess] = useState(false)
    const handleCloseSnackbar = () => {
        setShowErc20ApprovalSuccess(false)
        setShowStakeTokenSuccess(false)
    } */

    /* useEffect(() => {
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionStarted" &&
                notification.transactionName === "Approve ERC20 transfer").length > 0) {
            console.log("Approval Pending...")
            setShowErc20ApprovalPending(true)
            setShowErc20ApprovalSuccess(false)
            setShowStakeTokenPending(false)
            setShowStakeTokenSuccess(false)
        }
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionSucceed" &&
                notification.transactionName === "Approve ERC20 transfer").length > 0) {
            console.log("Approved!")
            setShowErc20ApprovalPending(false)
            setShowErc20ApprovalSuccess(true)
            setShowStakeTokenPending(false)
            setShowStakeTokenSuccess(false)


        }
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionStarted" &&
                notification.transactionName === "Stake Tokens").length > 0) {
            console.log("Stake Transaction Pending...")
            setShowErc20ApprovalPending(false)
            setShowErc20ApprovalSuccess(false)
            setShowStakeTokenPending(true)
            setShowStakeTokenSuccess(false)
        }
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionSucceed" &&
                notification.transactionName === "Stake Tokens").length > 0) {
            console.log("Tokens Staked!")
            setShowErc20ApprovalPending(false)
            setShowErc20ApprovalSuccess(false)
            setShowStakeTokenPending(false)
            setShowStakeTokenSuccess(true)
        }
    }, [notifications, showErc20ApprovalSuccess, showStakeTokenSuccess]) */


    return (
        <div>
            <p> Token: {name}, {symbol} <br />
                Token Address: {address} <br /><br />
            </p>
            {symbol === "MBOI" ? (
                <div>
                    {userAccountFunded ? (
                        <>
                            <p color="success">You can request more tokens when your balance is below 2k</p>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={() => handleTokenRequestSubmit(userAccount, address, '1000')}
                                color="primary"
                                variant="contained"
                                size="large"
                                disabled={isMining || state.status == "PendingSignature" || userAccountFunded}>
                                {isMining || state.status == "PendingSignature" ? <CircularProgress size={26} /> : "Request 1000 Tokens!!"}
                            </Button>
                            <p>Token Transfer Status: {status}</p>
                        </>
                    )}
                
                </div>
            ) : ( 
                <>
                    <div>
                        <h4>
                            You can only earn Harmoony tokens by owning NFTs
                        </h4>
                    </div>
                
                </>  
            )}
            <p>
                <BalanceMsg
                    label={`Your un-staked ${name} balance:`}
                    amount={formattedTokenBalance}
                    tokenImgSrc={image} />
            </p>
        </div>
    )
}