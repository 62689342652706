import { useEffect, useState } from "react"
import { useEthers, useContractFunction } from "@usedapp/core"
import { constants, utils, ethers } from "ethers"
import TokenFarm from "../chain-info/contracts/TokenFarm.json"
import ERC20 from "../chain-info/contracts/MockERC20.json"
import { Contract } from "@ethersproject/contracts"
import networkMapping from "../chain-info/deployments/map.json"
import brownieConfig from "../brownie-config.json"
import Hmy_NftSavageBeast from "../chain-info/contracts/Hmy_NftSavageBeast.json"
import MoonBoiToken from "../chain-info/contracts/MoonBoiToken.json"
import helperConfig from "../helper-config.json"
import { formatUnits } from "@ethersproject/units"
import {CreateMetadata} from '../data/CreateMetadata'


export const useMintNft = () => {
    const { account, chainId } = useEthers()
    const networkName = chainId ? helperConfig[chainId] : "harmony-ganache"
    let stringChainID = String(chainId)
    const provider = ethers.getDefaultProvider(brownieConfig["networks"][networkName]["provider"])
    const proxySavageBeastAddress = chainId ? networkMapping[stringChainID]["Hmy_NftSavageBeast_TransparentUpgradeableProxy"][0] : constants.AddressZero
    const proxySavageBeastABI = Hmy_NftSavageBeast["abi"]
    const proxySavageBeastInterface = new utils.Interface(proxySavageBeastABI)
    const proxySavageBeastContract = new Contract(proxySavageBeastAddress, proxySavageBeastInterface)
    const readOnlyProxySavageBeastContract = new Contract(proxySavageBeastAddress, proxySavageBeastABI, provider)
    const MoonBoiTokenProxyAddress = chainId ? networkMapping[stringChainID]["MoonBoiToken_TransparentUpgradeableProxy"][0] : constants.AddressZero
    const MoonBoiTokenABI = MoonBoiToken["abi"]
    const proxyMoonBoiTokenInterface = new utils.Interface(MoonBoiTokenABI)
    const proxyMoonBoiTokenContract = new Contract(MoonBoiTokenProxyAddress, proxyMoonBoiTokenInterface)



    
    
    const { send: payMintFee, state: payMintFeeState  } = useContractFunction(proxyMoonBoiTokenContract, 'transfer', { transactionName: 'Transfer' })
    const { send: mintNFT, state: mintNFTState  } = useContractFunction(proxySavageBeastContract, 'createCollectible', { transactionName: 'Mint NFT' })
    const { send: setTokenUri, state: setTokenUriState } = useContractFunction(proxySavageBeastContract, 'setTokenURI', {transactionName: 'Set Token URI'})
    
    const TransferTokens = async (_AddressTo: string, _amount: string) => {
        console.log('Paying mint Fee.')
        await payMintFee(_AddressTo, utils.parseEther(_amount)).then(() => {
            console.log('Token transfer complete.')
        })
    }

    const MintNft = async () => {
        console.log('Minting NFT.')
        const tokenId = await readOnlyProxySavageBeastContract.tokenCounter().then((tokenID_: any) => {
            const tokenCounter = parseFloat(formatUnits(tokenID_['_hex'], 0))
            const tokenId = tokenCounter
            console.log(tokenId)
            return(tokenId)
        })
        const randomMod = await readOnlyProxySavageBeastContract.getRandomness().then((_randomMod: number) => {
            console.log(_randomMod['_hex'])
            const formattedRandomMod = parseFloat(formatUnits(_randomMod['_hex'], 0))
            console.log(formattedRandomMod)
            return(formattedRandomMod)
        })

        const tokenUri = await CreateMetadata(tokenId, readOnlyProxySavageBeastContract, chainId!, randomMod )

        await mintNFT(constants.AddressZero!, account, tokenId, randomMod, tokenUri ).then(() => {
        })
    }

    // const _CreateMetadata = async () => {
    //     console.log("Getting token counter")
    //     const tokenId = await readOnlyProxySavageBeastContract.tokenCounter().then((tokenID_: any) => {
    //         const tokenCounter = parseFloat(formatUnits(tokenID_['_hex'], 0))
    //         const tokenId = tokenCounter - 1
    //         console.log(tokenId)
    //         return(tokenId)
    //     })
    //     const tokenUri = await CreateMetadata(tokenId, readOnlyProxySavageBeastContract, chainId!).then((tokenUri: any) => {
    //         return(tokenUri)
    //     })

    //     await setTokenUri(tokenId, tokenUri, account).then(() => {
    //         console.log(tokenUri)
    //     })

    //     return(tokenUri)
    // }


    useEffect(() => {
        if(payMintFeeState.status === 'Success') {
            console.log(`Mint Fee Paid`)
            console.log(payMintFeeState.status)
            MintNft()
        }else {
            console.log(payMintFeeState.status)
            if(payMintFeeState.status === 'Exception'){
                console.log(payMintFeeState.errorMessage)
            }
        }
    }, [payMintFeeState])

    useEffect(() => {
        if(mintNFTState.status === 'Success') {
            console.log(`NFT minted`)
            console.log(mintNFTState.status) 
        }else {
            console.log(mintNFTState.status)
            if(mintNFTState.status === 'Exception'){
                console.log(mintNFTState.errorMessage)
            }
        }
    }, [mintNFTState])

    // useEffect(() => {
    //     if(setTokenUriState.status === 'Success') {
    //         console.log(`Token URI is set!`)
    //         console.log(setTokenUriState.status) 
    //     }else {
    //         console.log('Setting token URI...')
    //         console.log(setTokenUriState.status)
    //         if(setTokenUriState.status === 'Exception'){
    //             console.log(setTokenUriState.errorMessage)
    //         }
    //     }
    // }, [setTokenUriState])

    return { TransferTokens, payMintFeeState, mintNFT, mintNFTState,  setTokenUri, setTokenUriState }
}