import React, { useState } from "react"
import { NFTs } from "../Main"
import { Box, makeStyles } from "@material-ui/core"
import { TabContext, TabList, TabPanel } from "@material-ui/lab"
import { Tab } from "@material-ui/core"
import dontRushMeSonny from "../../img/dontRushMeSonny.gif"

interface YourNFTsProps {
    supportedNFTs: Array<NFTs>
}


const useStyles = makeStyles((theme) => ({
    tabContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(4)
    },
    box: {
        backgroundColor: "white",
        borderRadius: "25px",
        display: "flex"
    },
    header: {
        color: "white"
    }
}))

export const Marketplace = () => {

    const classes = useStyles()
    return (
        <div>
            <div>
                <img src={dontRushMeSonny} width="600px"/>
            </div>
        </div>
    )
}